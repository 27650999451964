<!--
 *                        _oo0oo_
 *                       o8888888o
 *                       88" . "88
 *                       (| -_- |)
 *                       0\  =  /0
 *                     ___/`---'\___
 *                   .' \\|     |// '.
 *                  / \\|||  :  |||// \
 *                 / _||||| -:- |||||- \
 *                |   | \\\  - /// |   |
 *                | \_|  ''\---/''  |_/ |
 *                \  .-\__  '-'  ___/-. /
 *              ___'. .'  /--.--\  `. .'___
 *           ."" '<  `.___\_<|>_/___.' >' "".
 *          | | :  `- \`.;`\ _ /`;.`/ - ` : | |
 *          \  \ `_.   \_ __\ /__ _/   .-` /  /
 *      =====`-.____`.___ \_____/___.-`___.-'=====
 *                        `=---='
 *
 *
 *      ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *            佛祖保佑     永不宕机     永无BUG
 *
 * @Author: DY
 * @Date: 2022-11-11 14:37:15
 * @LastEditTime: 2022-11-18 15:42:05
 * @Description: 输入验证码
 * @FilePath: \BusinessPlatform\src\views\login\components\resetPassword\verificationCode.vue
 -->

<template>
    <div class="verificationCode">
        <h4>输入验证码</h4>
        <p>6位数验证码将发送至手机  <span>{{ viewParameter.phoneNumber }}</span></p>
        <div>
            <el-form
                ref="ref_verification"
                class="form-inline"
                :rules="rules"
                :inline="true"
                :model="formInline"
            >
                <el-form-item prop="verification">
                    <el-input v-model="formInline.verification" placeholder="请输入短信验证码">
                        <i slot="prefix" class="iconfont iconyanzhengma"></i>
                    </el-input>
                    <el-button
                        type="primary"
                        :disabled="disable"
                        :class="{ codeGeting: isGeting }"
                        @click="getVerificationCode"
                    >
                        {{ btnNmae }}
                    </el-button>
                </el-form-item>
            </el-form>
        </div>
        <el-button
            type="primary"
            class="loginBtn"
            @click="nextStep"
        >
            下 一 步
        </el-button>
    </div>
</template>

<script>
export default {
    name: 'verification-code',
    components: {},
    props: {
        viewParameter: {
            type: [Object],
        },
    },
    data() {
        return {
            formInline: {
                verification: '',
            },
            rules: {
                verification: [
                    { required: true, message: '验证码不能为空' },
                ],
            },

            // 验证码登录-获取验证码按钮状态
            btnNmae: '获取验证码',
            isGeting: false,
            count: 60,
            disable: false,
        };
    },
    watch: {},
    created() {},
    mounted() {},
    computed: {},
    methods: {
        // 手机登录获取验证码
        async getVerificationCode() {
            if (this.viewParameter.phoneNumber) {
                const sent = await this.getVerificationCodeRequrst(this.viewParameter.phoneNumber);
                if (sent) {
                    const countDown = setInterval(() => {
                        if (this.count < 1) {
                            this.isGeting = false;
                            this.disable = false;
                            this.btnNmae = '获取验证码';
                            this.count = 60;
                            clearInterval(countDown);
                        } else {
                            this.isGeting = true;
                            this.disable = true;
                            this.btnNmae = this.count-- + 's后重新发送';
                        }
                    }, 1000);
                }
            } else {
                this.$message({
                    showClose: true,
                    message: '没有获取到手机号码',
                    type: 'warning',
                });
            }

        },
        // 下一步
        nextStep() {
            this.$refs.ref_verification.validate(async valid => {
                if (valid) {
                    const res = await this.checkVerificationCode();
                    if (res === 'ok') {
                        const nextStepData = {
                            stepNo: 2,
                            phoneNumber: this.viewParameter.phoneNumber,
                            verification: this.formInline.verification.toString(),
                        };
                        this.$emit('nextStep', nextStepData);
                    }
                }
            });
        },

        // 获取短信验证码请求
        getVerificationCodeRequrst(phoneNumber) {
            return this.$axios.$get(`/interfaceApi/auth/verifycode/${phoneNumber}/2`, { defEx: true });
        },

        // 校验验证码
        checkVerificationCode() {
            const requstData = {
                phone_number: this.viewParameter.phoneNumber,
                verify_code: this.formInline.verification.toString(),
                usage: 2,
            };
            return this.$axios.$post('/interfaceApi/auth/checkverifycode', requstData, { defEx: true });
        },
    },
    updated() {},
    destroyed() {},
};
</script>

<style lang="stylus" scoped>
.verificationCode
    h4
        font-size 0.32rem
        color #001A2C
        margin-bottom: 0.1rem
    >p
        font-size 0.16rem
        line-height: 0.56rem
        color #001A2C
        span
            color #868FA3
    >div
        height 1.3rem
        .el-input
            width calc(100% - 1.1rem)
            line-height: 0.36rem
        .el-button
            width 1rem
            margin-left: 0.1rem
            font-size 0.14rem
            line-height: 0.34rem
            padding 0
    .loginBtn
        width 100%
</style>
