import { render, staticRenderFns } from "./verificationCode.vue?vue&type=template&id=3640db88&scoped=true"
import script from "./verificationCode.vue?vue&type=script&lang=js"
export * from "./verificationCode.vue?vue&type=script&lang=js"
import style0 from "./verificationCode.vue?vue&type=style&index=0&id=3640db88&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3640db88",
  null
  
)

export default component.exports